.mat-select-panel .mat-option.mat-selected {
  background-color: $infrest-background-color-light !important;
}


// mat-option with Button inside

mat-option {
  display: flex;
  gap: 16px;

  mat-icon {
    color: var(--color-primary);
    font-weight: bold;
    margin-top: -4px;
    margin-left: -4px;
  }
}

mat-option:has(mat-icon){
  //border-bottom: 1px solid var(--color-border);

  .mat-option-text {
    color: var(--color-primary);
    font-weight: bold;
  }
}



