.inf-formly-read-mode {
  margin-bottom: 2px;
  background-color: white;
  padding-inline: 1rem;
  padding-block: 0.75rem;
  border-radius: var(--border-radius);

  .inf-formly-read-mode__label {
    color: var(--color-text-label);
  }
  .inf-formly-read-mode__value {
    color: #333;
    font-weight: bold;
  }
}

formly-field.half-width:has(.inf-formly-compact-read-mode),
formly-field.third-width:has(.inf-formly-compact-read-mode),
formly-field.two-third-width:has(.inf-formly-compact-read-mode),
{
  flex: 1 1 100%;
}

.inf-formly-compact-read-mode {
  margin-bottom: 2px;
  background-color: white;
  //padding-inline: 1rem;
  padding-block: 0.5rem;
  border-top: 1px solid var(--color-border);
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__label{
    color: var(--color-text-label);
  }
  &__value{
    color: #333;
    font-weight: bold;
  }
}

.inf-formly-compact-read-mode-array {
  margin-bottom: 2px;
  background-color: white;
  padding-bottom: 1rem;
  border-top: 1px solid var(--color-border);


  &__header {
    font-weight: bold;
    color: #333;
    padding-block: 0.5rem;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid var(--color-border);
    padding-block: 0.5rem;
  }

  &__label{
    color: var(--color-text-label);
  }
  &__value{
    color: #333;
    font-weight: bold;
  }
}




