.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.padding-1 {
  padding: 0.5em !important;
}

.margin-left-1 {
  margin-left: 0.5em !important;
}

.margin-top-1 {
  margin-top: 0.5em !important;
}

.margin-top-2 {
  margin-top: 1em !important;
}

.margin-top-3 {
  margin-top: 1.5em !important;
}

.margin-top-4 {
  margin-top: 2em !important;
}

.padding-top-2 {
  padding-top: 2em !important;
}

.padding-bottom-2 {
  padding-bottom: 2em !important;
}

.padding-top-3 {
  padding-top: 3em !important;
}

.layout-main {
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  background: $infrest-background-color-light;
  background-size: cover;
}

//.flex-field-group {
//  display: flex;
//  flex-wrap: wrap;
//  align-items: center;
//  justify-items: stretch;
//  justify-content: space-between;
//  column-gap: 10px;
//
//  .full-width {
//    flex: 1 1 100%;
//  }
//  .half-width {
//    flex: 1 1 45%;
//  }
//  .third-width {
//    flex: 1 1 30%;
//  }
//  .two-thirds-width {
//    flex: 1 1 60%;
//  }
//
//  .new-area {
//    flex: 1 1 100%;
//    margin-top: 24px;
//  }
//}

.flex-field-group {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;

  formly-field {
    grid-column: 1 / -1;
  }

  .full-width {
    grid-column: 1 / -1;
  }

  .half-width {
    grid-column: span 3;
    grid-template-columns: 1fr;
  }
  .third-width {
    grid-column: span 2;
  }
  .two-thirds-width {
    grid-column: span 4;
  }

  .new-area {
    grid-column: 1 / -1;
    margin-top: 24px;
  }
}

