// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'styles/colors';
@import 'styles/formly-field-classes';
@import 'styles/layout';
@import 'styles/open-layers';
@import 'styles/font-sizes.scss';

@import 'styles/am_button';
@import 'styles/am_checkbox';
@import 'styles/am_common';
@import 'styles/am_drawer';
@import 'styles/am_input';
@import 'styles/am_snackbar';
@import 'styles/am_stepper';
@import 'styles/am_button_toggle_group';
@import 'styles/am_toolbar';
@import 'styles/note';
@import 'styles/am_tab.scss';
@import 'styles/am_card.scss';
@import 'styles/am_select.scss';

@import 'styles/formly-button.scss';
@import 'styles/basic-primary-button.scss';
@import 'styles/file-link.scss';
@import 'styles/formly-read-model';
@import 'styles/formly-text';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$infrest-lap-frontend-primary: mat.define-palette($infrest-palette-primary, A700, A100, A200, A400);
$infrest-lap-frontend-accent: mat.define-palette($infrest-palette-primary, 500, A100, A200, 500);

// The warn palette is optional (defaults to red).
$infrest-lap-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$infrest-lap-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $infrest-lap-frontend-primary,
      accent: $infrest-lap-frontend-accent,
      warn: $infrest-lap-frontend-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($infrest-lap-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

snack-bar-container.snackbar-success {
  background-color: $infrest-success-background-color !important;
  color: $infrest-success-color !important;
}

* {
  box-sizing: border-box;
}
