// open layers controls - not happy to have this here globally
@import '../node_modules/ol/ol.css';
@import '../styles/font-sizes.scss';

/* OpenLayers TODO: move to open-layers.scss */
.ol-zoom {
  display: flex;
  justify-content: space-around;
  height: auto;
  overflow: hidden;
  top: auto;
  left: auto;
  right: 16px;
  bottom: 16px;
  // filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24));
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 20%)) drop-shadow(0px 2px 2px rgb(0 0 0 / 14%));
  // drop-shadow(0px 1px 5px 0px rgb(0 0 0 / 12%));
  // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  button.ol-zoom-in,
  button.ol-zoom-out {
    background: white;
    color: #000000;
    font-size: calc($font-size-big + 2px);
    font-weight: 100;
    height: 48px;
    width: 48px;
    margin: 0;

    &:hover {
      background: #f5f5f5 !important;
      cursor: pointer;
    }

    &:active {
      background: #ebebeb !important;
    }
  }

  button.ol-zoom-in {
    border-radius: 4px 0px 0px 4px;
    border-right: 0.5px solid $infrest-standard-border-color;
  }
  button.ol-zoom-out {
    border-left: 0.5px solid $infrest-standard-border-color;
    border-radius: 0px 4px 4px 0px;
    padding-bottom: 3px;
  }
}

.ol-zoom,
.ol-control {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.ol-attribution {
  //display: none;
}
.ol-attribution.ol-uncollapsible {
  bottom: 16px;
  left: 72px;
  right: unset;
  border-radius: 4px 0 0;
}

.ol-scale-line {
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 35px - 30px);
  right: 136px;
  left: unset;
  max-width: 50%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.54);
  border-radius: 4px;
  height: 48px;
}

.ol-scale-line,
.ol-scale-line-inner {
  border-color: $infrest-black-medium-emphasize;
  color: $infrest-black-medium-emphasize;
  font-size: inherit;
  bottom: auto;
}

.ol-scale-line-inner {
  //background-color: $infrest-background-color-light;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  width: 85%;
  margin: 0.35em;
}
