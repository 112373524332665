@import '../styles/font-sizes.scss';

.mat-form-field-flex {
  height: 100% !important;
  padding: 0.9em 1em 0 1em !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-disabled .mat-hint {
  display: none !important;
}

.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-form-field-subscript-wrapper {
  padding: 2px 0 2px 14px !important;
  margin-top: 4px !important;
  position: relative !important;

  .mat-hint,
  .mat-error {
    margin-bottom: 1em;
  }
}

.mat-form-field-underline {
  bottom: 0;
  position: relative !important;
}

textarea.mat-input-element.cdk-textarea-autosize {
  line-height: 1.7em;
}

.mat-h1,
.mat-h2 {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: $font-size-large !important;
  line-height: 30px !important;
  letter-spacing: 0.15px !important;
}

.mat-h1 {
  color: $infrest-black-high-emphasize !important;
}

.mat-h2 {
  color: $infrest-black-medium-emphasize !important;
}
