//.mat-step-icon-selected {
//  background-color: #18a8e5 !important;
//}
//
//.mat-step-icon-state-edit {
//  background-color: #18a8e5 !important;
//}

//.mat-step-header .mat-step-icon:not(.mat-step-icon-selected) {
//  background: #9E9E9E !important;
//}

.mat-step-header:hover,
.mat-step-header.cdk-program-focused {
  background-color: transparent !important;
}

.mat-toolbar.mat-secondary {
  background: #fff !important;
}

.mat-horizontal-stepper-header-container {
  margin-bottom: 0 !important;
}
