.mat-card {
  padding: 0 !important;
}

.form-card {
  padding: 16px !important;
}

.wer-card {
  margin: 16px !important;
  padding: 16px !important;
}

.dynamic-table-card {
  box-shadow: none !important;
}
