//needs to be defined globally because angular prevents using styling when passed as innerHtml as done in the note component
.file-link:link,
.file-link:visited {
  color: $infrest-primary-variant-700;
  text-decoration: none;
}

.file-link:link:active,
.file-link:visited:active {
  color: $infrest-primary-variant-700;
  text-decoration: none;
}
