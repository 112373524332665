.mat-snack-bar-container {
  margin-left: auto !important; // center align from left
  margin-right: auto !important; // center align from right
  margin-bottom: 1em !important;
  padding: 10px !important; // spacing between the text and boundary
  background-color: $infrest-error-background-color !important;
  color: #c62828 !important;
  align-items: center !important;
  display: flex !important;

  .mat-button-wrapper {
    color: $infrest-error-color !important; // action text color
  }

  .mat-simple-snackbar-action {
    flex-shrink: 0;
    margin: -8px 0 -8px 8px;
  }
}

.mat-snack-bar-container.snackbar-success {
  background-color: $infrest-success-background-color !important;
  color: $infrest-success-color !important;
}

.mat-snack-bar-container.snackbar-success .mat-simple-snackbar-action .mat-button-wrapper {
  color: rgb(19, 79, 168) !important;
}
