mat-checkbox.ng-invalid.ng-touched {
  .mat-checkbox-frame {
    border-color: $infrest-error-color !important;
  }

  .mat-checkbox-label {
    color: $infrest-error-color !important;
  }
}

mat-selection-list.mat-selection-list {
  div.mat-list-item-content {
    align-items: flex-start !important;
  }
}

.mat-form-field-type-checkbox {
  .mat-form-field-flex {
    background: transparent !important;
    padding-left: 0 !important;
    padding-top: 0 !important;

    .mat-form-field-infix {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      border-top: 0;
    }
  }
}

.checkbox {
  .mat-form-field-flex {
    background: transparent !important;
    //padding-left: 0 !important;
    //padding-top: 0 !important;

    .mat-form-field-infix {
      //padding-bottom: 0 !important;
      //padding-top: 0 !important;
      border-top: 0;
      border: none;
    }
  }

  .mat-form-field-flex:hover {
    .mat-form-field-underline {
      .mat-form-field-ripple {
        opacity: 0;
      }
    }
  }

  .mat-form-field-underline::before {
    background-color: transparent !important;
  }
}

.checkbox-error {
  color: $infrest-error-color;
  position: relative;
  margin-top: 5px;
  height: 0;
  opacity: 0;
  visibility: hidden;
  font-size: 75%;

  &:before,
  &::before {
    position: absolute;
    content: '';
    width: 0;
    opacity: 0;
    height: 1px;
    background-color: $infrest-error-color;
    top: -5px;
  }

  &.show {
    height: auto;
    opacity: 1;
    margin-bottom: 1rem;
    visibility: visible;

    &:before,
    &::before {
      width: 100%;
      opacity: 1;
      transition: width 600ms ease-in-out, opacity 750ms linear;
    }
  }
}
