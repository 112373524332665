/* Has to be defined globally, adding locally to dynamic form component not sufficient */

.formly-field-float-25 {
  display: block;
  float: left;
  width: 24%;
  min-width: 100px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-33 {
  display: block;
  float: left;
  width: 32%;
  min-width: 140px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-50 {
  display: block;
  float: left;
  width: 49%;
  min-width: 200px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-full {
  display: block;
  float: left;
  width: 99%;
  min-width: 400px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-clear {
  clear: left;
}

.display-flex {
  display: flex;
  gap: 20px;
}

.v-centered-fields {
  // TODO check if this can be added to .display-flex
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

[class*='flex-']:first-child {
  padding-left: 0;
}

[class*='flex-']:last-child {
  padding-right: 0;
}

.link {
  padding: 0 !important;
}

.mat-form-field-flex:has(input:required) {
  background-color: $infrest-warning-background-color;
  border: 1px solid $infrest-warning-border-color;
}

.mat-form-field-appearance-fill {
  &.mat-form-field-disabled {
    .mat-form-field-flex:has(input:required) {
      background-color: rgba(0, 0, 0, 0.02);
      border: 1px solid transparent;
    }
  }
}

.mat-form-field-flex:has(textarea:required) {
  background-color: $infrest-warning-background-color;
  border: 1px solid $infrest-warning-border-color;
}

.mat-form-field-appearance-fill .mat-form-field-flex:has(.mat-select.mat-select-required) {
  background-color: $infrest-warning-background-color;
  border: 1px solid $infrest-warning-border-color;
}

.mat-form-field-appearance-fill .mat-form-field-flex:has(.mat-select[required]) {
  background-color: $infrest-warning-background-color;
  border: 1px solid $infrest-warning-border-color;
}

.mat-form-field.ng-invalid.ng-touched .mat-form-field-flex {
  background-color: $infrest-error-background-color;
  border: 1px solid $infrest-error-color;
}

.mat-form-field:has(inf-formly-field-mat-checkbox) {
  .mat-form-field-subscript-wrapper {
    padding: 2px 0 2px 24px !important;
    margin-top: 4px !important;
    position: relative !important;

    .mat-hint,
    .mat-error {
      margin-bottom: 1em;
    }
  }
}
