.mat-tab-header {
  background-color: white;
}

.mat-ink-bar {
  background-color: $infrest-primary-variant-700 !important;
}

.mat-tab-body {
  background-color: $infrest-background-color-light !important;
}

.mat-tab-label-active {
  color: $infrest-primary-variant-700 !important;
}

.mat-expansion-panel {
  border-left: 1px solid $infrest-standard-border-color;
  border-right: 1px solid $infrest-standard-border-color;
}

.mat-expansion-panel:first-of-type {
  border-top: 1px solid $infrest-standard-border-color;
}

.mat-expansion-panel:last-of-type {
  border-bottom: 1px solid $infrest-standard-border-color;
}
